<template>
  <Div :darkMode="darkMode">
    <Layout class="layout" :class="rtl ? 'ant-layout-rtl' : ''" @click="closeSidebarOnTapOutside">
      <Header class="layout-header">
        <router-link
          :class="
            topMenu && innerWidth > 991
              ? 'striking-logo top-menu'
              : 'striking-logo'
          "
          to="/"
        >
          <img
            :src="
              !darkMode
                ? require(`../static/img/white-logo.svg`)
                : require(`../static/img/white-logo.svg`)
            "
            alt="logo"
          />
        </router-link>

        <div class="user-info-desktop">
          <AuthInfo />
        </div>
        <sdButton
          v-if="!topMenu || innerWidth <= 991"
          @click="toggleCollapsed"
          type="white"
          class="burger-menu-header"
        >
          <img
            :src="
              require(`@/static/img/icon/${
                collapsed ? 'right.png' : 'left.png'
              }`)
            "
            alt="menu"
          />
        </sdButton>

        <div class="mobile-action">
          <chat v-if="!authStore.restrictions.includes(Restrictions['UseChat'])"></chat>
          
          <a class="btn-auth" @click="onShowHide(hide)" href="#">
            <sdFeatherIcons type="more-vertical" />
          </a>
        </div>
        <!-- </a-col> -->
        <!-- </a-row> -->
        <div class="banner-bar" v-if="!!banner">
          {{ t(`${banner}`, banner) }}
        </div>
      </Header>
      <div class="header-more">
        <a-row>
          <a-col :sm="24" :xs="24">
            <div class="small-screen-header-right">
              <SmallScreenAuthInfo :hide="hide" :darkMode="darkMode">
                <AuthInfo :rtl="rtl" />
              </SmallScreenAuthInfo>
            </div>
          </a-col>
        </a-row>
      </div>
      <Layout :class="rtl ? 'ant-layout-rtl' : ''" :style="{ 'height': '100vh' }">
        <template v-if="!topMenu || innerWidth <= 991">
          <Sider
            :width="280"
            :style="{
              margin: !!banner ? '100px 0 0 0' : '64px 0 0 0',
              padding: '30px 15px 155px 15px',
              overflowY: 'auto',
              height: '100vh',
              position: 'fixed',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"
            id="side-bar"
          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <PortfolioBasketSwitcher :collapsed="collapsed" />
              <AsideItems
                :toggleCollapsed="toggleCollapsedMobile"
                :topMenu="topMenu"
                :rtl="rtl"
                :darkMode="darkMode"
                :events="onEventChange"
                :class="rtl ? 'ant-menu-rtl' : ''"
              />
            </perfect-scrollbar>
          </Sider>
        </template>
        <Layout
          :style="{
            'margin-top': !!banner ? '150px' : '64px',
            'margin-left': '80px'
          }"
          class="atbd-main-layout"
          :class="rtl ? 'ant-layout-rtl' : ''"
        >
          <Content :style="{ 'display': 'flex', 'flex-direction': 'column' }">
            <div class="layout-main-content">
              <Suspense>
                <template #default>
                  <router-view
                    v-if="authStore.user && authStore.config"
                  ></router-view>
                  <div class="spin" v-else>
                    <a-spin />
                  </div>
                </template>
                <template #fallback>
                  <div class="spin">
                    <a-spin />
                  </div>
                </template>
              </Suspense>
            </div>

            <Footer
              class="admin-footer"
              :style="{
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
              }"
            >
              <a-row>
                <a-col :xs="24">
                  <span class="admin-footer__copyright"
                    >© {{ new Date().getFullYear() }} Brokvisor SL </span
                  >
                  <br>
                  <span class="admin-footer__copyright"
                    >{{ t('generic.disclaimer_morning_star') }}</span
                  >
                </a-col>
              </a-row>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Div>
</template>
<script setup>
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { Layout } from "ant-design-vue";
import { Div, SmallScreenAuthInfo } from "./style";

import AuthInfo from "../components/utilities/auth-info/info";
import AsideItems from "./Aside";
// import TopMenu from "./TopMenuItems";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { useThemeStore } from "@/stores/ThemeStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";
import PortfolioBasketSwitcher from "@/components/wiseral/portfolio/PortfolioBasketSwitcher.vue";
import { getTitleFromPath } from "../utility/helpers";
import chat from "@/components/wiseral/chat/chat.vue";
import Restrictions from "../config/restriction/restrict_functions";

const { Header, Footer, Sider, Content } = Layout;
import { infoToast } from "@/utility/toast";

const collapsed = ref(true);
const hide = ref(true);
const themeStore = useThemeStore();
const authStore = useAuthStore();
const route = useRoute();
const { t } = useI18n();
const banner = ref(null);

const rtl = computed(() => themeStore.rtlData);
const darkMode = computed(() => themeStore.data);
const topMenu = computed(() => themeStore.topMenu);

themeStore.collapsedMenu = window.innerWidth <= 1200 && true;

let ws = null;

onMounted(() => {
  const userId = authStore?.user?.id;
  const user = authStore?.user;

   // Verificamos si `user` y `userId` no son undefined
  if (user && userId) {
    console.log(`${process.env.VUE_APP_FASTAPI_WS_ENDPOINT}/${userId}`);
    ws = new WebSocket(`${process.env.VUE_APP_FASTAPI_WS_ENDPOINT}/${userId}`);
  
    ws.onmessage = (event) => {
      const eventData = JSON.parse(event?.data);
      const eventType = eventData?.eventType;
      const eventContent = JSON.parse(eventData?.eventMessage);
      const customer = eventContent?.customer?.email || eventContent?.transaction?.customer?.email;
      const itemPrices = eventContent?.subscription?.subscription_items?.map(item => item?.item_price_id).join('\r\n');
    
      const message = (
        <>
          {eventType.split('_')[0].charAt(0).toUpperCase() + eventType.split('_')[0].slice(1)} {eventType.split('_')[1]} by {customer} for this plan. <br />
          {itemPrices}
        </>
      );
    
      infoToast("Chargebee says...", message);
    
      // authStore.getActivePlan();
      setTimeout(() => {
        window.location.reload();
      }, 300);
    };
  } else {
    console.log("User or User ID is undefined, WebSocket connection not established.");
  }
});

watch(
  () => collapsed.value,
  () => {
    themeStore.collapsedMenu = collapsed.value;
  }
);

const toggleCollapsed = (e) => {
  e.preventDefault();
  collapsed.value = !collapsed.value;
};
const onShowHide = (h) => {
  hide.value = !h;
};

const toggleCollapsedMobile = () => {
  if (innerWidth <= 1150) {
    collapsed.value = !collapsed.value;
  }
};

const closeSidebarOnTapOutside = (event) => {
  if (innerWidth < 1150) {
    if ((!event.target.closest(".ant-layout-sider") && !event.target.closest(".burger-menu-header")) || event.target.closest(".router-link-active")) {
      collapsed.value = true;
    }
  }
};

const rtlInit = (dir, mode) => {
  const html = document.querySelector("html");
  html.setAttribute("dir", dir);
  themeStore.changeRtlMode(mode);
};

const onRtlChange = () => {
  rtlInit("rtl", true);
};

const onLtrChange = () => {
  rtlInit("ltr", false);
};

window.addEventListener("load", () => {
  const html = document.querySelector("html");
  themeStore.rtlData == true
    ? html.setAttribute("dir", "rtl")
    : html.setAttribute("dir", "ltr");
});

const modeChangeDark = () => {
  themeStore.changeLayoutMode(true);
};

const modeChangeLight = () => {
  themeStore.changeLayoutMode(false);
};

const modeChangeTopNav = () => {
  themeStore.changeMenuMode(true);
};

const modeChangeSideNav = () => {
  themeStore.changeMenuMode(false);
};

const onEventChange = {
  onRtlChange,
  onLtrChange,
  modeChangeDark,
  modeChangeLight,
  modeChangeTopNav,
  modeChangeSideNav,
};

const setBanner = (path) => {
  if (innerWidth < 1150) {
    collapsed.value = true;
  }
  banner.value = getTitleFromPath(path);
}

watch(
  () => route.path,
  (path) => {
    setBanner(path);
  }
);

const openSidebar = () => {
  collapsed.value = false;
};

const closeSidebar = () => {
  collapsed.value = true;
}

onMounted(() => {
  setBanner(route.path);
  
  const sideBar = document.getElementById('side-bar');
  if (sideBar) {
    sideBar.addEventListener("mouseenter", openSidebar);
    sideBar.addEventListener("mouseleave", closeSidebar);
  }
});

onUnmounted(() => {
  const sideBar = document.getElementById('side-bar');
  if (sideBar) {
    sideBar.addEventListener("mouseenter", openSidebar);
    sideBar.addEventListener("mouseleave", closeSidebar);
  }
});
</script>
<style lang="scss" scoped>
.ps {
  height: calc(100vh - 100px);
}

.layout-header {
  position: fixed;
  width: 100%;
  top: 0;
  max-width: 100vw;
  background: linear-gradient(90deg, #004851 0%, #02B388 100%);
  padding-right: 48px;
}

.burger-menu-header {
  position: absolute;
  top: 12px;
  right: 60px;
  margin-left: 20px;
}

.banner-bar {
  position: absolute;
  width: 100%;
  height: 48px;
  left: 0;
  background: #04cea5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
}

.btn-auth {
  margin-right: 20px;
}

.user-info-desktop {
  margin-top: -65px;
  width: max-content;
  margin-left: auto;
}

.layout-main-content {
  flex-grow: 1;
  background-color: #F2F2F2;
  padding: 8px 96px;
}

@media only screen and (max-width: 768px) {
  .layout-main-content {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 1150px) {
  .burger-menu-header {
    display: none;
  }
  
  .btn-auth {
    display: none !important;
  }

  .small-screen-header-right{
    display: none;
  }
}

@media only screen and (max-width: 1149px) {
  .burguer {
    &.burger-menu-menu {
      display: none;
    }
  }

  .user-info-desktop {
    display: none;
  }
}
</style>
