<template>
  <router-link
    :to="item.destination"
    v-if="item"
    @click="() => {}"
    :key="$t(item.title)"
    size=""
  >
    <li class="nav-item">
      <div
        class="icono"
        :style="iconElement(item)"
      ></div>
      <span class="text">{{ $t(item.title) }}</span>
    </li>
  </router-link>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    item: {
      required: false,
      type: Object,
    },
  },
  methods: {
    iconElement: (item) => {
      return {
        "-webkit-mask": "url(" + item.icon + ") no-repeat center",
        mask: "url(" + item.icon + ") no-repeat center",
      };
    },
  },
};
</script>

<style lang="scss">
@import "src/static/css/colors";
.nav-item {
  border-radius: 100px;
  display: flex;
  align-items: center;
  clear: both;
  padding: 0.5em 0.5em;
  margin-bottom: 0.7em;
  .icono {
    margin: 0 0.5em 0 0.5em;
    width: 2em;
    height: 1.6em;
    float: left;
    background-color: $text-light-color;
  }
  .text {
    color: $text-dark-color;
  }
  &:hover {
    .text {
      color: $text-light-color;
      font-weight: 500;
      display: block;
    }
    .icono {
      background-color: $text-light-color;
    }
  }
}
</style>
